import React, { useState, useContext, useEffect, useRef } from "react";
import ResponsiveAppBar from "../../components/Navbar";
import { getInformation, update } from "../../services/customer";
import { UserContext } from "../../context/UserProvider.jsx";
import { useNavigate } from "react-router-dom";
import Tab from "./tab.jsx";
import {
  TextField,
  Grid,
  Container,
  Paper,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { getList } from "../../services/admin";
import Select from "react-select";
import { isMobile } from "react-device-detect";
import LoadingScreen from "../../components/LoadingScreen";

const App = () => {
  const navigate = useNavigate();
  const [customerData, setCustomerData] = useState(null);
  const [initUpdate, setInitUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const { token } = useContext(UserContext);
  const { id } = useParams();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);
    getInformation(token, id)
      .then((res) => {
        setCustomerData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const handleUpdate = (payload) => {
    setLoading(true);

    let payloadData = {
      "company-name": payload.companyName,
      "account-number": payload.accountNumber,
      "address-line-1": payload.addressLine1,
      "address-line-2": payload.addressLine2,
      email: payload.emailAddress,
      phone: payload.phoneNumber,
      password: payload.password,
      "sales-person": payload.salesPerson,
      "send-reminder": payload.sendReminder,
    };

    if (payload?.emailAddress.includes("-(disabled)")) {
      payloadData["token"] = payload?.emailAddress;
    }

    update(token, payloadData, id).then((res) => {
      if (res.status && res.status === 405) {
        setLoading(false);
        return alert(res.data.message);
      }

      alert("Customer information updated successfully!");
      setLoading(false);
      loadData();
      setInitUpdate();
    });
  };

  if (!customerData) {
    return null;
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth={"xl"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <h1>Customer Information</h1>
          </Grid>
          <Grid item xs={12} md={8}>
            <Tab />
          </Grid>
        </Grid>
        <div
          style={{
            height: "80vh",
            width: "100%",
            backgroundColor: "#ffff",
            marginTop: "20px",
          }}
        >
          <Paper elevation={0} sx={{ padding: 3 }}>
            {!initUpdate ? (
              <InformationView
                customerData={customerData}
                setInitUpdate={setInitUpdate}
              />
            ) : (
              <UpdateForm
                customerData={customerData}
                onSubmit={handleUpdate}
                setInitUpdate={setInitUpdate}
              />
            )}
          </Paper>
        </div>
      </Container>
    </>
  );
};

const InformationView = ({ customerData, setInitUpdate }) => {
  return (
    <div style={isMobile ? { overflow: "scroll" } : {}}>
      <table className="table-information">
        <tbody>
          <tr>
            <td className="caption">
              <strong>Sales Person: </strong>
            </td>
            <td>
              {customerData["sales-person"]
                ? customerData["sales-person"].label
                : ""}
            </td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Account Number: </strong>
            </td>
            <td>{customerData["account-number"] ?? ""}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Company Name: </strong>
            </td>
            <td>{customerData["company-name"] ?? ""}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Address Line 1: </strong>
            </td>
            <td>{customerData["address-line-1"] ?? ""}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Address Line 2: </strong>
            </td>
            <td>{customerData["address-line-2"] ?? ""}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Phone Number: </strong>
            </td>
            <td>{customerData["phone"] ?? ""}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Email Address: </strong>
            </td>
            <td>{customerData["email"] ?? ""}</td>
          </tr>
          <tr>
            <td className="caption">
              <strong>Send Order Reminder: </strong>
            </td>
            <td>
              {customerData["send-reminder"] == 0 ||
              !customerData["send-reminder"]
                ? "Do not send reminder"
                : `Send after ${customerData["send-reminder"]} days from the last date of order`}
            </td>
          </tr>
        </tbody>
      </table>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        style={{ marginTop: "20px", float: "right" }}
        onClick={() => setInitUpdate(true)}
      >
        Update
      </Button>
    </div>
  );
};

const UpdateForm = ({ customerData, onSubmit, setInitUpdate }) => {
  const { token } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    companyName: customerData["company-name"],
    accountNumber: customerData["account-number"],
    addressLine1: customerData["address-line-1"],
    addressLine2: customerData["address-line-2"],
    emailAddress: customerData["email"],
    phoneNumber: customerData["phone"],
    password: null,
    salesPerson: customerData["sales-person"],
    sendReminder: customerData["send-reminder"] ?? 0,
  });
  const [salesPersonList, setSalesPersonList] = useState([]);

  useEffect(() => {
    getList(token, {
      "user-specific": false,
    }).then((res) => {
      let tempSalesPersonList = [];
      res.collection.forEach((item) => {
        if (item.data.access.control["sales person"] === true) {
          tempSalesPersonList.push({
            value: item.raw.id,
            label: item.data.name,
          });
        }
      });

      setSalesPersonList(tempSalesPersonList);
    });
  }, []);

  const handleSelectChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      salesPerson: event,
    }));
  };

  const handleGeneratePassword = () => {
    const randomPassword = Math.random().toString(36).slice(-8);
    setFormData((prevFormData) => ({
      ...prevFormData,
      password: randomPassword,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formData);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleDisable = () => {
    let confirmation = window.confirm(
      "Are you sure you want to disable this customer?"
    );
    if (confirmation) {
      onSubmit({
        ...formData,
        emailAddress: `${formData.emailAddress}-(disabled)`,
        token: "",
      });
    }
  };

  const handleEnable = () => {
    let confirmation = window.confirm(
      "Are you sure you want to enable this customer?"
    );
    if (confirmation) {
      onSubmit({
        ...formData,
        emailAddress: formData.emailAddress.replace("-(disabled)", ""),
        token: "",
      });
    }
  };

  const isDisabled = formData.emailAddress.includes("-(disabled)");

  return (
    <Paper elevation={0} sx={{ padding: 3 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Select
              defaultValue={formData.salesPerson}
              required
              options={salesPersonList}
              onChange={handleSelectChange}
              placeholder="Sales Person *"
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  background: "#fff",
                  borderColor: "#9e9e9e",
                  height: "55px",
                  boxShadow: state.isFocused ? null : null,
                }),

                valueContainer: (provided, state) => ({
                  ...provided,
                  height: "55px",
                  padding: "0 6px",
                }),

                input: (provided, state) => ({
                  ...provided,
                  margin: "0px",
                }),
                indicatorSeparator: (state) => ({
                  display: "none",
                }),
                indicatorsContainer: (provided, state) => ({
                  ...provided,
                  height: "55px",
                }),
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Company Name"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Account Number"
              name="accountNumber"
              value={formData.accountNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Address Line 1"
              name="addressLine1"
              value={formData.addressLine1}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address Line 2"
              name="addressLine2"
              value={formData.addressLine2}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Email Address"
              name="emailAddress"
              type="email"
              value={formData.emailAddress}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Send Order Reminder (Number of Days from Last Order date)"
              name="sendReminder"
              value={formData.sendReminder}
              onChange={handleChange}
              type="number"
              inputProps={{ min: 0, max: 100 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={formData.password}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleGeneratePassword}>
              Generate Password
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
            <Button
              type="button"
              variant="contained"
              style={{ marginLeft: "10px" }}
              color="primary"
              onClick={() => setInitUpdate(false)}
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="contained"
              style={{ marginLeft: "10px" }}
              color="error"
              onClick={() => (isDisabled ? handleEnable() : handleDisable())}
            >
              {isDisabled ? "Enable" : "Disable"} Customer
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default App;
