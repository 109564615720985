import * as React from 'react'
import TextField from '@material-ui/core/TextField'
import Select from 'react-select'

const generateDeliveryTimes = () => {
  const deliveryTimes = []
  let start = 8
  let end = 17
  let ampm = 'AM'
  for (let i = start; i <= end; i++) {
    for (let j = 0; j < 60; j += 60) {
      let hour = i
      let minute = j
      if (i > 12) {
        hour = i - 12
        ampm = 'PM'
      }
      deliveryTimes.push({
        value: `${hour}:${j === 0 ? '00' : j} ${ampm}`,
        label: `${hour}:${j === 0 ? '00' : j} ${ampm}`,
      })
    }
  }
  return deliveryTimes
}

const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#9e9e9e',
    height: '55px',
    boxShadow: state.isFocused ? null : null,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: '55px',
    padding: '0 6px',
  }),
  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: (state) => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '55px',
  }),
}

const OrderForm = React.forwardRef(({ defaultData }, ref) => {
  const contactPersonRef = React.useRef(null)
  const contactDetailsRef = React.useRef(null)
  const deliveryAddressRef = React.useRef(null)
  const paymentTypeRef = React.useRef(null)
  const deliveryTimeRef = React.useRef(null)
  const deliveryDateRef = React.useRef(null)
  const deliveryNoteRef = React.useRef(null)

  const submitData = () => {
    return {
      contactPerson: contactPersonRef.current.value,
      contactDetails: contactDetailsRef.current.value,
      deliveryAddress: deliveryAddressRef.current.value,
      deliveryNote: deliveryNoteRef.current.value,
      deliveryDate: deliveryDateRef.current.value,
      paymentType: paymentTypeRef.current?.getValue()[0]?.value ?? null,
      deliveryTime: deliveryTimeRef.current?.getValue()[0]?.value ?? null,
    }
  }

  React.useImperativeHandle(ref, () => ({
    submitData: submitData,
  }))

  React.useEffect(() => {
    console.log('defaultData', defaultData)
  }, [])

  const deliveryTimes = generateDeliveryTimes()

  const textFields = [
    {
      placeholder: 'Contact Person Name *',
      ref: contactPersonRef,
      size: '50%',
      defaultValue: defaultData?.contactPerson || '',
    },
    {
      placeholder: 'Contact Details *',
      ref: contactDetailsRef,
      size: '50%',
      defaultValue: defaultData?.contactDetails || '',
    },
    {
      placeholder: 'Delivery Address *',
      ref: deliveryAddressRef,
      size: '100%',
      defaultValue: defaultData?.deliveryAddress || '',
    },
    {
      placeholder: 'Delivery Note',
      ref: deliveryNoteRef,
      size: '100%',
    },
  ]

  const today = new Date().toISOString().split('T')[0]

  return (
    <div
      style={{
        backgroundColor: '#ffff',
        padding: '20px',
        borderRadius: '5px',
      }}
    >
      <h2>Order Information</h2>
      {textFields.map((field, index) => (
        <div
          key={index}
          style={{
            width: field.size || '100%',
            display: 'inline-block',
            verticalAlign: 'top',
          }}
        >
          <TextField
            variant="outlined"
            placeholder={field.placeholder}
            fullWidth
            inputRef={field.ref}
            defaultValue={field.defaultValue || ''}
          />
        </div>
      ))}
      <div
        style={{
          width: '25%',
          display: 'inline-block',
          verticalAlign: 'top',
          marginTop: '10px',
        }}
      >
        <label>Preferred Delivery Date *</label>
        <TextField
          variant="outlined"
          placeholder={'Preferred Delivery Date *'}
          fullWidth
          inputRef={deliveryDateRef}
          type="date"
          inputProps={{ min: today }}
          defaultValue={defaultData?.deliveryDate || today}
        />
      </div>
      <div
        style={{
          width: '25%',
          display: 'inline-block',
          verticalAlign: 'top',
          marginTop: '10px',
        }}
      >
        <label>Payment Type *</label>
        <Select
          options={[
            { value: 'Cash', label: 'Cash' },
            { value: 'Check', label: 'Check' },
            { value: 'Credit Card On File', label: 'Credit Card On File' },
          ]}
          placeholder="Payment Type *"
          ref={paymentTypeRef}
          styles={customSelectStyles}
        />
      </div>
      <div
        style={{
          width: '50%',
          display: 'inline-block',
          verticalAlign: 'top',
          marginTop: '10px',
        }}
      >
        <label>Preferred Delivery Time *</label>
        <Select
          options={deliveryTimes}
          placeholder="Preferred Delivery Time *"
          ref={deliveryTimeRef}
          styles={customSelectStyles}
        />
      </div>
    </div>
  )
})

export default OrderForm
